import React from 'react';
import { Link, ButtonLink, ProgressBar } from 'components';
import TutorialHeader from 'assets/image/pages/tutorial/step3.png';

const Step3 = () => {
  return (
    <div className="tutorial__step">
      <div className="tutorial__step-image">
        <img
          src={TutorialHeader}
          alt="Step 3"
        />
      </div>
      <div className="tutorial__step-content">
        <h2 className="tutorial__step-title">Business Management</h2>
        <p className="tutorial__step-description">
          Our A.I. powered receptionists manage all your calls and appointments
          <strong> 24/7/365</strong>.
        </p>
        <ButtonLink
          variant="secondary"
          large
          round
          className="mb-5"
          to="/"
          sizeX={'182px'}
        >
          Next&nbsp;&nbsp; <i className="fas fa-long-arrow-alt-right"></i>
        </ButtonLink>
        <Link
          to="/dashboard"
          className="tutorial__step-skip"
        >
          Skip
        </Link>
        <ProgressBar focus={3} />
      </div>
    </div>
  );
};

export default Step3;

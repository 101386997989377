import React from 'react';
import { Link, Button, ProgressBar } from 'components';
import TutorialHeader from 'assets/image/pages/tutorial/step2.png';

const Step2 = ({ navigation = {} }) => {
  const { next } = navigation;

  return (
    <div className="tutorial__step">
      <div className="tutorial__step-image">
        <img
          src={TutorialHeader}
          alt="Step 2"
        />
      </div>
      <div className="tutorial__step-content">
        <h2 className="tutorial__step-title">Calendar & Appointments</h2>
        <p className="tutorial__step-description">
          Our A.I. powered receptionists manage all your calls and appointments
          <strong> 24/7/365</strong>.
        </p>
        <Button
          variant="secondary"
          large
          round
          className="mb-5"
          onClick={next}
          sizeX={'182px'}
        >
          Next&nbsp;&nbsp; <i className="fas fa-long-arrow-alt-right"></i>
        </Button>
        <Link
          to="/dashboard"
          className="tutorial__step-skip"
        >
          Skip
        </Link>
        <ProgressBar focus={3} />
      </div>
    </div>
  );
};

export default Step2;

import React from 'react';
import { useForm, useStep } from 'react-hooks-helper';

import { AuthPageWrapper, Link, BrandLogo } from 'components';
import { Step1, Step2, Step3 } from 'templates/tutorial';

const steps = [
  { id: 'step1' },
  { id: 'step2' },
  { id: 'step3' },
];

const Tutorial: React.VFC = () => {
  const [formData, setForm] = useForm({});
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;
  const props = { formData, setForm, navigation };

  const renderStep = (id, props) => {
    switch (id) {
      case 'step1':
        return <Step1 {...props} />;
      case 'step2':
        return <Step2 {...props} />;
      case 'step3':
        return <Step3 {...props} />;
      default:
        return null;
    }
  };

  return (
    <AuthPageWrapper paddingTop={'100px'}>
      <Link to="/">
        <BrandLogo className="register__logo" />
      </Link>
      <div className="tutorial__steps">
        {renderStep(id, props)}
      </div>
      <div className="tutorial__footer">
        © {(new Date().getFullYear())} Voicio. Alright Reserved.
      </div>
    </AuthPageWrapper>
  );
};

export default Tutorial;
